import styled, { css } from 'styled-components'
import { BP, Colors, Rem } from '../../commons/Theme'
import { StyledButton } from '../Button/style'
import { StyledIcon } from '../Icon/style'
import { StyledGridRow } from '../../commons/Grid'
import styledMap from 'styled-map'
import { Menu } from '@3beehivetech/components'

export const StyledHeader = styled.header`
  position: fixed;
  align-items: center;
  border-radius: ${Rem(10)};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
  transition: height 1s ease;
  transition: transform 1s ease-out 0s;
  height: ${Rem(66)};
  overflow: hidden;
  top: ${Rem(12)};

  ${(props) =>
    props.menuType === 'biodiversity' &&
    css`
      position: absolute;
      top: ${Rem(10)};
    `}

  background-color: ${styledMap`
    yellow: ${Colors.yellow};
    black: ${Colors.yellow};
    default: ${Colors.white};
  `};
  z-index: 10;
  width: 92%;
  left: 5%;

  @media (${BP.tablet}) {
    width: ${Rem(500)};
    left: calc(50% - ${Rem(250)});
  }

  ${(props) =>
    props.isVisible &&
    css`
      height: ${Rem(500)};

      @media (${BP.ipad}) {
        height: ${Rem(580)};
      }
    `}

  ${(props) =>
    !props.isVisible &&
    props.direction === 'DOWN' &&
    css`
      transform: translateY(${Rem(-68)});
    `}

  ${StyledGridRow} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    white-space: nowrap;
    padding: 0;
    height: ${Rem(66)};

    .has-share-corner & {
      @media (${BP.large}) {
        max-width: ${Rem(1100)};
      }

      @media (${BP.big}) {
        max-width: ${Rem(1248)};
      }
    }
  }

  ${StyledButton} {
    color: ${styledMap`
      yellow: ${Colors.black};
      black: ${Colors.black};
      default: ${Colors.white};
    `};
    background-color: ${styledMap`
      yellow: ${Colors.white};
      black: ${Colors.white};
      default: ${Colors.yellow};
    `};
  }

  [data-page='apicoltori'] & {
    margin-bottom: ${Rem(24)};

    @media (${BP.ipad}) {
      margin-bottom: ${Rem(32)};
    }
  }
`
export const StyledHeaderScript = styled.div`
  text-align: center;
  font-size: ${Rem(16)};
  padding-left: 0;
  padding-right: 0;
  @media (${BP.ipad}) {
    font-size: ${Rem(24)};
  }
`

export const StyledHeaderIcon = styled.button`
  color: rgba(0, 0, 0, 0);
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  ${StyledIcon} {
    width: ${(props) => Rem(props.size ?? 30)};
    height: ${(props) => Rem(props.size ?? 30)};

    svg {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
`

export const StyledHeaderHiveIcon = styled.button`
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  ${StyledIcon} {
    width: ${Rem(36)};
  }

  ${(props) =>
    props.right &&
    css`
      position: absolute;
      right: 10px;
      @media (${BP.ipad}) {
        position: relative;
      }
    `}
`

export const StyledContainerBee = styled.div`
  position: fixed;
  width: ${Rem(400)};
`
export const StyledHeaderBlock = styled.div`
  display: inline-block;
  padding: ${Rem(4)} ${Rem(4)};
  align-items: center;
  vertical-align: middle;
  text-align: center;

  @media (${BP.ipad}) {
    padding: ${Rem(4)} ${Rem(16)};
  }

  ${(props) =>
    props.hasMenu &&
    css`
      width: 100%;
      max-width: ${Rem(900)};
    `}

  ${(props) =>
    props.latest &&
    css`
      padding-right: ${Rem(64)};

      @media (${BP.ipad}) {
        padding-right: ${Rem(16)};
      }
    `}

  ${StyledButton} {
    min-width: ${Rem(66)};
    padding: 0 ${Rem(6)};
    height: ${Rem(32)};
    margin-right: ${Rem(16)};
    font-size: ${Rem(12)};

    @media (${BP.ipad}) {
      font-size: inherit;
    }

    + a ${StyledIcon} {
      text-decoration: none;
      width: ${Rem(32)};
      cursor: pointer;
    }
  }

  ${(props) =>
    props.theme.black &&
    css`
      ${StyledButton} {
        color: ${Colors.white};
        background-color: ${Colors.yellow};
      }
    `}
`
export const StyledHeaderRoutePage = styled.div`
  ${StyledButton} {
    box-shadow: 0 ${Rem(2)} ${Rem(4)} 0 ${Colors.black50};
  
    @media (${BP.ipad}) {
      box-shadow: 0 ${Rem(2)} ${Rem(4)} 0 ${Colors.black50};
    }

`
export const StyledHeaderLogo = styled.div``

export const StyledHeaderLogoImage = styled.img`
  width: ${Rem(60)};
  @media (${BP.ipad}) {
    width: ${Rem(70)};
  }
`
export const StyledHamburgerItems = styled.div`
  text-align: center;
  z-index: 10;
  margin-top: ${Rem(40)};
  margin-bottom: ${Rem(60)};
  border-radius: ${Rem(10)};
  overflow: hidden;

  background-color: ${styledMap`
    yellow: ${Colors.yellow};
    black: ${Colors.yellow};
    default: ${Colors.white};
  `};
`

export const StyledHamburgerItem = styled.div`
  cursor: pointer;
  text-align: center;
  width: 100%;
  font-size: ${Rem(20)};
  line-height: ${Rem(35)};
  color: ${Colors.black};

  @media (${BP.ipad}) {
    font-size: ${Rem(24)};
    line-height: ${Rem(50)};
  }
`

export const StyledMenuContainer = styled.div`
  [class^=Menu-module_content] {
    max-height: calc((100vh - 80px) - 6rem);
    overflow: auto;
  }
`
