const icons = {
  arrowLeft: "ArrowLeft",
  arrowRight: "ArrowRight",
  arrowRightLong: "ArrowRightLong",
  audio: "Audio",
  audioOff: "Audio",
  bee: "Bee",
  person: "Person",
  calendar: "Calendar",
  checked: "Checked",
  chart: "Chart",
  checkOk: "CheckOk",
  filtroRifiorisci: "filtroRifiorisci",
  chevronDown: "ChevronDown",
  chevronLeft: "ChevronLeft",
  chevronRight: "ChevronRight",
  clock: "Clock",
  coins: "Coins",
  cross: "Cross",
  facebook: "Facebook",
  globe: "Globe",
  hexagon: "Hexagon",
  hive: "Hive",
  card: "Card",
  honey: "Honey",
  honeydripper: "Honeydripper",
  instagram: "Instagram",
  libra: "Libra",
  locker: "Locker",
  man: "Man",
  marker: "Marker",
  message: "Message",
  minus: "Minus",
  phoneChat: "PhoneChat",
  piggyBank: "PiggyBank",
  play: "Play",
  play2: "Play2",
  plus: "Plus",
  report: "Report",
  sensor: "Sensor",
  tech: "Tech",
  school: "School",
  sustainable: "Sustainable",
  sensors: "Sensors",
  beekeeper: "Beekeeper",
  oscar: "Oscar",
  prizeGreen: "PrizeGreen",
  bee3Bee: "Bee3Bee",
  sun: "Sun",
  triangleLeft: "TriangleLeft",
  triangleRight: "TriangleRight",
  twitter: "Twitter",
  warn: "Warn",
  wifi: "Wifi",
  youtube: "Youtube",
  shop0: "Shop0",
  shop1: "Shop1",
  shop2: "Shop2",
  shopBee: "ShopBee",
  shopCrown: "ShopCrown",
  shopHoney: "ShopHoney",
  check: "Check",
  euFlag: "EuropeanUnionFlag",
  italianFlag: "ItalianFlag",
  search: "Search",
  honey2: "Honey2",
  certificate: "Certificate",
  gift: "Gift",
  pause: "Pause",
  phone: "Phone",
  hamburger: "Hamburger",
  share: "Share",
  ebook: "Ebook",
  leef: "Leef",
  shuttle3Bee: "Shuttle3Bee",
  workcase: "Workcase",
  shopstore: "ShopStore",
  businessman: "BusinessMan",
  info: "Info",
  pin: "Pin",
  certification: "Certification",
  help: "Help",
  oasi: "Oasi",
  bagbee: "BagBee",
  englandIcon: "EnglandIcon",
  frenchIcon: "FrenchIcon",
  germanyIcon: "GermanyIcon",
  italyIcon: "ItalyIcon",
  spainIcon: "SpainIcon",
  linkedin: "Linkedin",
  shieldbee: "ShieldBee",
  bee3: "3Bees",
  movie:"Movie",
  osmiahouse: "OsmiaHouse",
  audioOff:"AudioOff",
  stop: "Stop",
  house: "House",
  tree: "Tree",
  beenergy: "Beenergy",
  hive2: "Hive2",
  soil: "Soil",
  gift2: "Gift2",
  education: "Education",
  tech2: "Tech2",
  iceCream: "IceCream",
  team: "Team",
  multipleOasi: "MultipleOasi",
  stars: "Stars",
  human: "Human",
  support: "Support",
  phone2: "Phone2",
  polly: "Polly",
  beeCircles: "BeeCirlces",
  flower: "Flower",
  business: "Business",
  planet: "Planet",
  close: "Close",
};

export default icons;
