import styled from 'styled-components'
import { Rem, BP } from '../../commons/Theme'

export const StyledSelect = styled.div`
  width: 100%;
  display: flex;
  flex: wrap;
  column-gap: 16px;
  max-width: 250px;
  margin: 0px;
  align-items: center;
`

export const StyledLangFlag = styled.button`
  border-radius: 100%;
  width: ${Rem(25)};
  height: ${Rem(25)};
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  cursor: pointer;
  border: none;
  @media (${BP.tablet}) {
    width: ${Rem(37)};
    height: ${Rem(37)};
  }
`
