import { useState } from "react";

const isBrowser = typeof window !== "undefined";
const setCookie = (name, value, options) => {
  if (!isBrowser) return;

  const TWENTY_YEARS = 365 * 20;
  const optionsWithDefaults = {
    days: TWENTY_YEARS,
    path: "/",
    ...options,
  };
  const expires = new Date(
    Date.now() + optionsWithDefaults.days * 864e5,
  ).toUTCString();

  document.cookie
    = name
    + "="
    + encodeURIComponent(value)
    + "; expires="
    + expires
    + "; path="
    + optionsWithDefaults.path;
};
const getCookie = (name, initialValue = "") => {
  return (
    (isBrowser
      && document.cookie.split("; ").reduce((r, v) => {
        const parts = v.split("=");

        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
      }, ""))
    || initialValue
  );
};
const useCookies = (key, initialValue) => {
  const [item, setItem] = useState(() => {
    return getCookie(key, initialValue);
  });
  const updateItem = (value, options) => {
    setItem(value);
    setCookie(key, value, options);
  };

  return [item, updateItem];
};

export default useCookies;
