import React, { useContext, useRef, useState } from "react";
import {
  StyledFormRedeemCodeStatic, StyledReedemValidate,
  StyledReedemValidateText, StyledFormRedeemCodeContentStatic,
} from "./style";
import { DataContext } from "../../contexts/DataContext";
import { TextInput, Button } from "@3beehivetech/components";
import {
  StyledForm,
  StyledFormSubmit,

} from "../../commons/Form";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";
import useApi from "../../hooks/useApi";
import Loader from "../Loader/Loader";
import ApiErrorForm from "../ApiErrorForm/ApiErrorForm";
import { LayoutContext } from "../../contexts/LayoutContext";
import { useMenuContext } from "@3beehivetech/components";

export default () => {
  const { closeMenu } = useMenuContext();
  const { labels, i18nOasiLink, languages } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";
  const { checkRedeemCode } = useApi();
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [urlCoupon, setUrlCoupon] = useState("");
  const [couponDetails, setCouponDetails] = useState("");
  const [isValidCode, setIsValideCode] = useState(false);
  const inputEl = useRef(null);
  const { setModalContent } = useContext(LayoutContext);
  const handleSubmit = () => {
    setIsLoading(true);
    checkRedeemCode(inputEl.current.value).then((res) => {
      setIsLoading(false);
      if (res && res.error) {
        setModalContent(<ApiErrorForm message={res.message} />);
      } else {
        if (res) {
          setCouponDetails(res);
          setIsValideCode(true);
          setCanSend(false);
          setUrlCoupon(i18nOasiLink(`/alveari/?coupon=${inputEl.current.value}`));
        } else {
          setIsInvalidCode(true);
        }
      }
    });
  };

  return (
    <StyledFormRedeemCodeStatic isValidCode={isValidCode}>
      <StyledFormRedeemCodeContentStatic>
        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <TextInput
            type="text"
            placeholder={labels.REDEEMINSERT}
            hasError={isInvalidCode}
            errorMessage={labels.INVALID_CODE}
            ref={inputEl}
            onInput={() => {
              const isValid = inputEl.current.value.length > 3;

              setIsValideCode(false);
              setCanSend(isValid);
              if (isInvalidCode) setIsInvalidCode(false);
            }}
          />
          <Button style="text-transform: uppercase; width: 100%; margin-top: 20px;" disabled={!canSend}>
            {labels.REDEEM}{" "}
            <Icon icon={icons.arrowRight} color={Colors.white} />
          </Button>
        </StyledForm>
        {isValidCode && (
          <StyledReedemValidate>
            {couponDetails.purchaser ? (<StyledReedemValidateText dangerouslySetInnerHTML={{ __html:  labels.GIFT_DONATED_FROM_WHO + " " + couponDetails.quantity + " " + labels.GIFT_DONATED_FROM_WHAT + (couponDetails.discount_type === "gift_code" ? " " + labels.GIFT_CODE_GO_TO : "") }}></StyledReedemValidateText>)
              : (<StyledReedemValidateText dangerouslySetInnerHTML={{ __html: labels.COUPONOK }}></StyledReedemValidateText>)}
            <StyledFormSubmit onClick={() => {
              closeMenu();
              { couponDetails.discount_type === "gift_code" ? window.open(`https://3bee.page.link/?link=https://app.3bee.com/%23/?gift_code=${inputEl.current.value}&ibi=com.threebee.flutter&apn=com.threebee.threebee_flutter`) : window.location.href = urlCoupon; }

            }}>
              {couponDetails.discount_type === "gift_code" ? labels.GIFT_CODE_GO_TO : labels.START_COUPON_CHECKOUT}{" "}
              <Icon icon={icons.arrowRight} color={Colors.white} />
            </StyledFormSubmit>
          </StyledReedemValidate>)}
        <div style="padding-top: 20px;">
          <a style="font-size: 12px; font-weight: 100; text-decoration: underline;" href={`https://3bee-assets.s3.eu-south-1.amazonaws.com/documents/${currentLanguageCode}/termini_condizioni_voucher.pdf`}>{labels.COUPONRULES}</a>
        </div>
      </StyledFormRedeemCodeContentStatic>
      <Loader isVisible={isLoading} />
    </StyledFormRedeemCodeStatic>
  );
};

// <Icon icon={icons.checkOk} color={Colors.yellow} />
