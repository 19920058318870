import axios from "axios";

const baseApiUrl = process.env.BACKEND_API_URL;

const _sendError = (err, from) => {
  window.dataLayer.push({
    event: "customError",
    custom: {
      errorMessage: `${err.stack}`,
      errorSource: `useApi ${from}`,
    },
  });
};
const getPlants = ({
  coupon, lang, country, region, 
}) => {
  const countryParam = country ? `&country=${country}` : "";
  const couponParam = coupon ? `&coupon=${coupon}` : "";
  const regionParam = region ? `&region=${region}` : "";
  return axios
    .get(
      `${process.env.GATSBY_API_URL}honeys/?format=json${couponParam}${countryParam}${regionParam }`,
      {
        headers: { "Accept-Language": lang },
      },
    )
    .then((res) => res.data)
    .catch((err) => {
      _sendError(err, "getPlants");
      return {
        error: true,
        message: err.message,
      };
    });
};
// aggiungere eccezione array vuoto senza apicoltori
const getBeekeepers = (honeyID, coupon, rare, lang, country, { onlyWithHoney = false }) => {
  const honeyParam = honeyID ? `&honey_id=${honeyID}` : "";
  const rareParam = rare ? `&rare=${rare}` : "";
  const couponParam = coupon ? `&coupon=${coupon}` : "";
  const countryParam = country ? `&country=${country}` : "";
  const quantityParam = onlyWithHoney ? "&av_quantity_gte=0.5" : "";

  return axios
    .get(
      `${process.env.GATSBY_API_URL}beekeepers/?format=json${honeyParam}${rareParam}${couponParam}${countryParam}${quantityParam}`,
      {
        headers: { "Accept-Language": lang },
      },
    )
    .then((res) =>
      res.data.map((x) => {
        return {
          ...x,
          slug: x.fullname
            ? x.fullname.toLowerCase().replace(/([ \.,'`"*_])/g, "-")
            : null,
        };
      }),
    )
    .catch((err) => {
      _sendError(err, "getBeekeepers");
      return {
        error: true,
        message: err.message,
      };
    });
};
const getAdoptionPlans = (lang, country) => {
  return axios
    .get(
      `${process.env.GATSBY_API_URL}plans?format=json&country=${country}`,
      {
        headers: { "Accept-Language": lang },
      },
    )
    .then((res) => {
      return {
        data: res.data,
      };
    })
    .catch((err) => {
      _sendError(err, "getAdoptionPlans");
      return {
        error: true,
        message: err.message,
      };
    });
};
const getAdoptedHives = (purchaser, code) => {
  const honeyParam = purchaser ? `&purchaser=${purchaser}` : "";
  const couponParam = code ? `&code=${code}` : "";

  return axios
    .get(`${process.env.GATSBY_API_URL}hives/?format=json${honeyParam}${couponParam}&av_quantity_gte=0.5`)
    .then((res) => {
      return {
        ...res.data,
      };
    },
      /*res.data.map((x) => {
        return {
          ...x,
          slug: x.fullname ? x.fullname.toLowerCase().replace(/ /g, '-') : null,
        }
      })*/
    )
    .catch((err) => {
      _sendError(err, "getAdoptedHives");
      return {
        error: true,
        message: err.message,
      };
    });
};
const getKPI = () => {
  return axios
    .get(`${process.env.LAMBDA_INFO_API_URL}`)
    .then((res) => {
      return {
        ...res.data,
      };
    },
      /*res.data.map((x) => {
        return {
          ...x,
          slug: x.fullname ? x.fullname.toLowerCase().replace(/ /g, '-') : null,
        }
      })*/
    )
    .catch((err) => {
      _sendError(err, "getKPI");
      return {
        error: true,
        message: err.message,
      };
    });
};
const checkRedeemCode = (coupon) => {
  if (coupon != null) {
    return (
      axios
        .get(
          `${process.env.LAMBDA_GIFTCODES}coupon?format=json&code=${coupon}`,
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          _sendError(err, "checkRedeemCode");
          if (err.response && err.response.status === 400) {
            return false;
          }
          return {
            error: true,
            message: err.message,
          };
        })
    );
  } else {
    return null;
  }
};
const postAdoption = (name_surname, email, hive_id, honey_id, beekeeper_id, coupon, language = "it") => {
  return axios
    .post(
      `${process.env.LAMBDA_GIFTCODES}adoption?format=json`,
      {
        fullname: name_surname,
        email: email,
        hive_id: hive_id,
        honey_id: honey_id,
        beekeeper_id: beekeeper_id,
        lang: language,
        coupon: coupon,
      },
    )
    .then((res) => {
      return {
        data: res,
      };
    })
    .catch((err) => {
      _sendError(err, "postAdoption");
      return {
        error: true,
        message: err.message,
      };
    });
};

const getHives_v2 = ({
  honeyID, coupon, lang, region, page, country, rare, hiveId, planId, limitId, shuffleId, avQuantityGte, page_size,
}) => {
  const honeyParam = honeyID ? `&honey_id=${honeyID}` : "";
  const couponParam = coupon ? `&coupon=${coupon}` : "";
  const countryParam = country ? `&country=${country}` : "";
  const regionParam = region ? `&region=${region}` : "";
  const pageParam = page ? `&page=${page}` : "";
  const rareParam = rare ? `&rare=${rare}` : "";
  const hiveIdParam = hiveId ? `&hive_id=${hiveId}` : "";
  const planIdParam = planId ? `&target_plan=${planId}` : "";
  const limitParam = limitId ? `&limit=${limitId}` : "";
  const shuffleParam = shuffleId ? `&shuffle=${shuffleId}` : "";
  const pageSizeParam = page_size ? `&limit=${page_size}` : "";
  const avQuantityGteParam = avQuantityGte ? `&av_quantity_gte=${avQuantityGte}` : "&av_quantity_gte=0.5";
  
  return axios
    .get(
      `${process.env.GATSBY_API_URL_V2}hives/?format=json${honeyParam}${couponParam}${countryParam}${regionParam}${pageParam}${rareParam}${hiveIdParam}${planIdParam}${avQuantityGteParam}${shuffleParam}${limitParam}${pageSizeParam}&unique=1`,
      {
        headers: { "Accept-Language": lang },
      },
    )
    .then((res) => { return res.data; },
    )
    .catch((err) => {
      _sendError(err, "getHives");
      return {
        error: true,
        message: err.message,
      };
    });
};

const getRegions = ({ country }) => {
  const countryParam = country ? `&country=${country}` : "";
  return axios
    .get(
      `${process.env.GATSBY_API_URL}hives_regions/?${countryParam}`,
    )
    .then((res) => { return res.data; },
    )
    .catch((err) => {
      _sendError(err, "getRegions");
      return {
        error: true,
        message: err.message,
      };
    });
};

const getAddons = ({ country }) => {
  const countryParam = country ? `&country=${country}` : "";
  return axios
    .get(
      `${process.env.GATSBY_API_URL}valid_add_ons/?${countryParam}`,
    )
    .then((res) => res.data)
    .catch((err) => {
      _sendError(err, "getAddons");
      return {
        error: true,
        message: err.message,
      };
    });
};

const getCountries = () => {
  return axios
    .get(
      `${process.env.GATSBY_API_URL}adoption_plans_countries`,
    )
    .then((res) => res.data)
    .catch((err) => {
      _sendError(err, "getCountries");
      return {
        error: true,
        message: err.message,
      };
    });
};

const createUser = (userInfo, skipAddress) => {
  let payload = {
    email: userInfo.email,
    fullname: userInfo.fullname,
    locale: userInfo.locale,
  };
  if (!skipAddress) {
    payload["address"] = {
      street1: userInfo.street1,
      street_number: userInfo.street_number,
      zip: userInfo.zip,
      state: userInfo.state,
      country: userInfo.country,
      city: userInfo.city,
      force: userInfo.force,
      //street2: userInfo.street2
    };
  }
  return axios
    .post(
      `${baseApiUrl}auth/users/create_user_hook/`,
      payload,
    )
    .then((res) => {
      return {
        data: res,
      };
    })
    .catch((err) => {
      _sendError(err, "createUser");
      return {
        error: true,
        message: err.response.data,
      };
    });
};
const checkIfUserExist = (email) => {
  return axios
    .post(
      `${baseApiUrl}auth/users/check_user_email/`,
      {
        email: email,
      },
    )
    .then((res) => {
      return {
        data: res,
      };
    })
    .catch((err) => {
      _sendError(err, "checkIfUserExist");
      return {
        error: true,
        message: err.response.data,
      };
    });
};
const loginUser = ({ email, password }) => {
  return axios
    .post(
      `${baseApiUrl}auth/jwt/create/`,
      {
        email: email,
        password: password,
      },
    )
    .then((res) => {
      return {
        data: res,
      };
    })
    .catch((err) => {
      _sendError(err, "loginUser");
      return {
        error: true,
        message: err.response.data,
      };
    });
};
const updateAdoptionsEnd = (accessToken) => {
  return axios
    .post(
      `${baseApiUrl}adoptions/move_end_if_not_renewed/`,
      {
        days: 65,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    .then((res) => {
      return {
        data: res,
      };
    })
    .catch((err) => {
      _sendError(err, "updateAdoptionsEnd");
      return {
        error: true,
        message: err.response.data,
      };
    });
};
const getPaymentToken = (accessToken) => {
  return axios
    .post(
      `${process.env.PAYMENT_BASE_URL}/generate-client-token`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    .then((res) => {
      return {
        data: res,
      };
    });
};

const activeCouponStore = (accessToken, coupon_id) => {
  let payload = {
    user: "no",
  };
  return axios
    .patch(
      `${baseApiUrl}coupons/${coupon_id}/activate_store_coupon/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    .then((res) => {
      return {
        data: res,
      };
    })
    .catch((err) => {
      _sendError(err, "activeCouponStore ");
      return {
        error: true,
        message: err.response.data,
      };
    });

};

const pairCouponStore = (accessToken, store_user_id, coupon_id) => {
  let payload = {
    user: store_user_id,
  };
  return axios
    .patch(
      `${baseApiUrl}coupons/${coupon_id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    .then((res) => {
      return {
        data: res,
      };
    })
    .catch((err) => {
      _sendError(err, "pairCouponStore");
      return {
        error: true,
        message: err.response.data,
      };
    });
};

const getCouponstore = (accessToken) => {
  return axios
    .get(
      `${baseApiUrl}auth/users/?user_type=store&page_size=1000`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    .then((res) => {
      return {
        data: res,
      };
    })
    .catch((err) => {
      _sendError(err, "getCouponStore");
      return {
        error: true,
        message: err.response.data,
      };
    });
};

const getCoupons = (accessToken, user_id) => {
  const _userId = user_id ? `?user_id=${user_id}` : "";
  return axios
    .get(
      `${baseApiUrl}coupons/${_userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    .then((res) => {
      return {
        data: res,
      };
    })
    .catch((err) => {
      _sendError(err, "getCoupons");
      return {
        error: true,
        message: err.response.data,
      };
    });
};
const verifyCard = (accessToken, deviceData, nonce, email, amount = "0.01", to_void = true) =>{
  let payload = {
    payment_method_nonce: nonce,
    deviceData: deviceData,
    user_email: email,
    amount: amount,
    to_void: to_void,
  };
  return axios
    .post(
      "https://14a2d4a7pa.execute-api.eu-central-1.amazonaws.com/production/v1/verify_card/",
      payload,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    .then((res) => {
      return {
        data: res,
      };
    });
  
};
const uploadOasiPreviewImage = (file) => {
  const formData = new FormData();

  formData.append("file", file);
  return axios
    .post(
      process.env.CLOUDINARY_UPLOAD_URL,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )
    .then((res) => {
      return {
        imageUrl: res.data.secure_url,
      };
    })
    .catch((err) => {
      return {
        error: true,
      };
    });
};
const sendEmailMark = (emailTrakingId, token, mark) => {
  return axios
    .get(`${process.env.LAMBDA_BEESERVICES_URL}update_email_tracking?email_tracking_id=${emailTrakingId}&token=${token}&email_mark=${mark}`)
    .then((res) => {
      return {
        data: res.data,
      };
    })
    .catch((err) => {
      _sendError(err, "sendEmailMark");
      return {
        error: true,
        message: err.message,
      };
    });
};

const getStores = (lat, lng) => {
  return axios
    .get(
      lat && lng 
        ? `${process.env.GATSBY_API_URL}stores/map?latitude=${lat}&longitude=${lng}`
        : `${process.env.GATSBY_API_URL}stores/map`,
    )
    .then((res) => res.data)
    .catch((err) => {
      _sendError(err, "getStores");
      return {
        error: true,
        message: err.message,
      };
    });
};
const getRedeemTree = ({
  giftCode, email, name, surname, treeName,
}) => {
  let payload = {
    gift_code: giftCode,
    gifted_to_email: email,
    gifted_to_fullname: `${name} ${surname}`,
    custom_tree_name: treeName,
    lang_code: "it",
  };

  return axios
    .post(
      `${baseApiUrl}adopter_tree_adoptions/redeem_gift_code/`,
      payload,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      _sendError(err, "getRedeemTree");
      return {
        error: true,
        message: err.response.data,
      };
    });
};

const getTrees = ({
  lang, page, country, rare, limitId, treeId,
}) => {
  const countryParam = country ? `country=${country}` : "";
  const pageParam = page ? `&page=${page}` : "";
  const rareParam = rare ? `&rare=${rare}` : "";
  const limitParam = limitId ? `&limit=${limitId}` : "";
  const treeIdParam = treeId ? `&tree_id=${treeId}` : "";
  return axios
    .get(
      `${process.env.GATSBY_API_URL}adoptable-trees?${countryParam}${limitParam}${pageParam}${rareParam}${treeIdParam}`,
      {
        headers: { "Accept-Language": lang },
      },
    )
    .then((res) => { return res.data; },
    )
    .catch((err) => {
      _sendError(err, "getHives");
      return {
        error: true,
        message: err.message,
      };
    });
};

const useApi = () => {
  return {
    verifyCard,
    getCoupons,
    pairCouponStore,
    getCouponstore,
    activeCouponStore,
    getAdoptionPlans,
    getBeekeepers,
    getPlants,
    checkRedeemCode,
    postAdoption,
    getAdoptedHives,
    getHives_v2,
    getRegions,
    getAddons,
    getCountries,
    createUser,
    loginUser,
    checkIfUserExist,
    getPaymentToken,
    updateAdoptionsEnd,
    uploadOasiPreviewImage,
    getKPI,
    sendEmailMark,
    getStores,
    getRedeemTree,
    getTrees,
  };
};

export default useApi;
