import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledSelectionList = styled.div`
  margin-top: 10px;
`;

export const StyledSelectionListTitle = styled.div`
  text-align: left;
  font-size: ${Rem(16)};
  line-height: ${Rem(16)};
  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(18)};
  }

  > .title {
    ${props => props.isNew && css`
      &::after {
        content: "new";
        font-size: ${Rem(14)};
        display: inline-block;
        margin-left: 5px;
        color: ${Colors.yellow80};
      }
    `}
  }
`;

export const StyledSelectionListItemContainer = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: ${Colors.yellow40};
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex-shrink: 0;
  
  svg {
    max-width: 100%;
  }
`;

export const StyledGroup = styled.div`
  padding: 10px 0;
  color: ${Colors.yellow80};
  font-weight: bolder;
  text-transform: uppercase;
`;

export const StyledSelectionListItems = styled.div`
  display: grid;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 6px;
  box-sizing: border-box ;
  row-gap: 30px;
  flex-wrap: 1;
  grid-template-columns: 1fr;
  @media (${BP.ipad}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledSectionListItemWrapper = styled.div`
  max-height: 70vh;
  overflow: auto;
  padding-bottom: 5px;
`;

export const StyledSelectionListItem = styled.div`
  position: relative;
  display: flex;
  min-width : 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: 1;
  &:hover {
    cursor: pointer; 
  }
  @media (${BP.ipad}) {
    max-width: 290px;
    align-items: flex-start;
    margin: initial;
  }
`;

export const StyledSelectionListLabel = styled.div`
  text-align: left;
  font-size: ${Rem(12)};
  line-height: ${Rem(14)};
  font-weight: 100;
  color: ${Colors.black500};
  

  @media (${BP.tablet}) {
    font-size: ${Rem(14)};
    line-height: ${Rem(16)};
    margin-top: 3px;
    display: block;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledSelectionLine = styled.div`
    border-top: 3px solid #AAA9A9;
    display: block;
    margin-bottom: 10px;
    margin-top: 5px;
    width: 70px;
    @media (${BP.ipad}) {
      width: 140px;
    }
`;
