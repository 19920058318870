import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import { StyledSelect, StyledLangFlag } from "./style";
import useCookies from "../../hooks/useCookies";
import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context";

const LanguageSelectorFlagStyle = ({ onClick }) => {
  const location = useLocation();
  const [_, setCookie] = useCookies('CURRENT_LANG');
  const { localizedPaths } = useContext(DataContext);
  const { changeLanguage } = useContext(SnipcartContext);
  const flags = {
    "it": "/2020/images/flags/it.png", 
    "en": "/2020/images/flags/en.png", 
    "es": "/2020/images/flags/es.png", 
    "fr": "/2020/images/flags/fr.png", 
    "de": "/2020/images/flags/de.png", 
    "ru": "/2020/images/flags/ru.png", 
  };

  if (Object.keys(localizedPaths).length === 1) {
    return null;
  }
  return (
    <StyledSelect>
      {Object.keys(localizedPaths).map((lang, index) => (
        <Link key={index} to={`${localizedPaths[lang]}${location.search}`} onClick={() => {
          setCookie(lang);
          // Only on "Snipcart" pages there is changeLanguage
          if (changeLanguage) {
            changeLanguage(lang);
          }
          onClick();
        }}>
          <StyledLangFlag bg={flags[lang]} />
        </Link>
      ))}
    </StyledSelect>
  );
};

export default LanguageSelectorFlagStyle;
