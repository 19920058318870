import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
  Shadow,
} from "../../commons/Theme";
import { StyledIcon } from "../Icon/style";

export const StyledModal = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-out 0s;
  z-index: 999999;
  backdrop-filter: blur(${Rem(10)});

  ${(props) =>
    props.isVisible
    && css`
      opacity: 1;
      pointer-events: all;
    `}

  ${(props) =>
    props.loader
    && css`
      ${StyledModalBG} {
        pointer-events: none;
      }
      ${StyledModalContent} {
        background-color: ${Colors.transparent};
        box-shadow: none;
        border: none;
      }
      ${StyledModalCloser} {
        display: none;
      }
    `}
`;

export const StyledModalBG = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const StyledModalContent = styled.div`
  position: relative;
  width: ${(props) => props.useChildWidth ? "auto" : `calc(100% - ${Rem(48)})`};
  max-width: ${Rem(930)};
  max-height: 90%;
  overflow: ${(props) => props.useChildWidth ? "unset" : "auto"};
  padding: 0;
  background-color: ${Colors.white};
  ${Shadow};

  @media (${BP.ipad}) {
    width: auto;
    padding: ${(props) => props.useChildWidth ? 0 : `0 ${Rem(24)}`};
  }
`;

export const StyledModalCloser = styled.div`
  position: fixed;
  padding-top: ${Rem(6)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(20)};
  }

  ${StyledIcon} {
    position: relative;
    width: ${Rem(18)};
    left: ${Rem(6)};
    cursor: pointer;

    @media (${BP.ipad}) {
      width: ${Rem(22)};
      left: ${(props) => props.useChildWidth ? Rem(6) : Rem(-10) };
    }
  }
`;
