import styled from "styled-components";

export const BPSizes = {
  smartphone: 375,
  tablet: 768,
  ipad: 1024,
  desktop: 1280,
  large: 1440,
  big: 1680,
};

export const BP = {};

Object.keys(BPSizes).map((item) => {
  BP[item] = `min-width: ${BPSizes[item]}px`;
  return false;
});

BP.portrait = `max-width: ${BPSizes.ipad - 1}px`;
BP.twoColumns = `max-width: ${BPSizes.tablet - 1}px`;

export const Colors = {
  transparent: "transparent",
  black: "rgba(17, 17, 17, 1)",
  blackYellow: "rgba(112, 89, 36, 1)",
  blackGreen: "rgba(70, 101, 74, 1)",
  blackPink: "rgba(112, 71, 85, 1)",
  dark: "rgba(17, 17, 17, 1)",
  black50: "rgba(0, 0, 0, .5)",
  black500: "#686868",
  gray: "rgba(153, 153, 153, 1)",
  grayDark: "rgba(50, 50, 50, 1)",
  grayLight: "rgba(249, 249, 249, 1)",
  grayTransparent: "rgba(153, 153, 153, .85)",
  white: "rgba(255, 255, 255, 1)",
  white50: "rgba(255, 255, 255, .5)",
  white60: "rgba(255, 255, 255, .6)",
  white80: "rgba(255, 255, 255, .8)",
  yellow: "rgba(255, 176, 0, 1)",
  pink: "#FFE1E8",
  pinkText: "#F66E9C",
  greenlight: "#DAF4DE",
  ligthyellow: "#FFFAED",
  yellow20: "rgba(255, 176, 0, .2)",
  yellow40: "rgba(255, 176, 0, .4)",
  yellow60: "rgba(255, 176, 0, .6)",
  yellow80: "rgba(255, 176, 0, .8)",
  yellowClear: "rgba(255, 246, 226, 1)",
  red: "#EB0A0A",
  green: "#457B00",
  greenClear: "#00AF91",
  yellowPantone: "rgba(245, 223, 77, 1)",
  grayPantone: "rgba(147, 149, 151, 1)",
  gradient: "linear-gradient(180deg, #000000 0%, #1d1d1d 100%)",
  gradientInv: "linear-gradient(0deg, #000000 0%, #1d1d1d 100%)",
  greenPrimary: "#73A77B",
  redPrimary: "#E23A58",
};

export const Rem = (size) => {
  return `${size / 16}rem`;
};

export const Ratio = (w, h) =>
  `&:before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: ${(h / w) * 100}%;
  }`;

export const Sizes = {
  headerMobile: `${Rem(60)}`,
  headerDesktop: `${Rem(64)}`,
};

export const Font = {
  sans: "Inter",
  babas: "Babas",
};

export const Shadow = `
  box-shadow: 0 ${Rem(2)} ${Rem(4)} 0 ${Colors.yellow};
  border: 1px solid ${Colors.yellow};
`;

export const ShadowBlack = `
  box-shadow: 0 ${Rem(2)} ${Rem(4)} 0 ${Colors.black50};
  border: none
`;

export const ShadowWhite = `
  box-shadow: 0 ${Rem(2)} ${Rem(4)} 0 ${Colors.white};
  border: 1px solid ${Colors.white};
`;

export const StyledLogo = styled.a`
  display: block;
  position: absolute;
  width: ${Rem(100)};
  bottom: ${Rem(100)};
  left: ${Rem(20)};
  z-index: 4;
  text-decoration: none;

  @media (${BP.ipad}) {
    width: ${Rem(80)};
  }

  > span {
    display: inline-block;
    padding-bottom: ${Rem(6)};
    font-size: ${Rem(14)};
    color: ${Colors.grayLight};

    @media (${BP.ipad}) {
      font-size: ${Rem(12)};
    }
  }
`;
