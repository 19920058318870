import styled from "styled-components";
import {
  BP,
  Rem,
  Colors,
} from "../../commons/Theme";

export const StyledHamburgerItem = styled.div`
  cursor: pointer;
  text-align: left;   
  width: 100%;
  font-size: ${Rem(16)};
  line-height: ${Rem(20)};
  color: ${Colors.black};

  @media (${BP.ipad}) {
    font-size: ${Rem(20)};
    line-height: ${Rem(25)};
  }
`;

export const StyledSeparetor = styled.div`
  margin: 3px 0px; 
  border-bottom: 1px solid rgba(153, 153, 153, 0.5); 
  width: 100%; 
  margin-left: 0; 
  height: 1px;
  @media (${BP.ipad}) {
    margin: 5px 0px; 
  }
`;

export const StyledHamburgerSubItem = styled.div`
  cursor: pointer;
  text-align: left;   
  width: 100%;
  font-weight: 100;
  font-size: ${Rem(16)};
  line-height: ${Rem(20)};
  color: ${Colors.black500};
  margin-bottom: 3px;

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(30)};
  }
`;
