import React from "react";
import { StyledIconV2 } from "./style";
import loadable from "@loadable/component";

const Component = loadable(props => import(`./SVG/${props.iconName}`), {
  cacheKey: props => props.iconName,
});

export { default as icons } from "./icons";

export default ({ color = "black", icon, height }) => {
  return (
    <StyledIconV2 height={height}>
      <Component iconName={icon} color={color} height={height} />
    </StyledIconV2>
  );
};
