import React from "react";
import {
  StyledSelectionList,
  StyledSelectionListTitle,
  StyledSelectionListItems,
  StyledSelectionListItem,
  StyledSelectionListLabel,
  StyledSelectionListItemContainer,
  StyledGroup,
} from "./style.jsx";
import IconV2 from "../Icon/IconV2";
import { Colors } from "../../commons/Theme";
import { useMenuContext } from "@3beehivetech/components";
import Link from "../Link/Link";
const plan_color = [Colors.yellow40, Colors.pink, Colors.greenlight];

export default ({ items, urlParams, group }) => {
  const { closeMenu } = useMenuContext();
  const itemsWithUrl = items.filter((e) => e.url != null);

  if (itemsWithUrl.length == 0) {
    return <></>;
  }
  
  return (
    <StyledSelectionList>
      {group && <StyledGroup>{group}</StyledGroup>}
      <StyledSelectionListItems>
        {itemsWithUrl.map((item, index) => (
          <Link
            key={index}
            to={item.url}
            urlParams={urlParams}
          >
            <StyledSelectionListItem
              key={index}
              onClick={() => closeMenu()}
            >
              <StyledSelectionListItemContainer>
                <IconV2 icon={item.icon} height="17px" color={Colors.grayDark} />
              </StyledSelectionListItemContainer>

              <StyledSelectionListTitle isNew={item.isNew}>
                <div className="title" dangerouslySetInnerHTML={{ __html: item.title }} />
                <StyledSelectionListLabel
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </StyledSelectionListTitle>
            </StyledSelectionListItem>
          </Link>
        ))}
      </StyledSelectionListItems>
    </StyledSelectionList>
  );
};
