import React from "react";
import { StyledLayout } from "./style";
import GlobalStyle from "../../commons/GlobalStyle";
import { DataContextProvider } from "../../contexts/DataContext";
import HeaderFloat_3BC from "../HeaderFloat_3BC/HeaderFloat_3BC";
import Footer from "../Footer/Footer";
import { LayoutContextProvider } from "../../contexts/LayoutContext";
import { StyledGrid } from "../../commons/Grid";
import { MenuVisibilityProvider, ThreeBeeQueryProvider } from "@3beehivetech/components";
import useTheme from "../../hooks/useTheme";

const Layout = (props) => {
  return (
    <div data-page={props.pageContext.originalPath}>
      <GlobalStyle />
      <DataContextProvider
        {...props.pageContext}
        path={props.pageContext.originalPath}
      >
        <LayoutContent useLayout={props.pageContext.useLayout}>
          {props.children}
        </LayoutContent>
      </DataContextProvider>
    </div>
  );
};

const LayoutContent = ({ useLayout, children }) => {
  /// Update theme color
  useTheme();

  return !useLayout ? (
    <StyledGrid>
      <StyledLayout>{children}</StyledLayout>
    </StyledGrid>
  ) : (
    <ThreeBeeQueryProvider>
      <MenuVisibilityProvider>
        <LayoutContextProvider>
          <div className="mx-auto max-w-screen-3xl">
            <HeaderFloat_3BC />
          </div>
          <StyledGrid>
            <StyledLayout>{children}</StyledLayout>
            <Footer />
          </StyledGrid>
        </LayoutContextProvider>
      </MenuVisibilityProvider>
    </ThreeBeeQueryProvider>
  );
};

export default Layout;
