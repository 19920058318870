import { createGlobalStyle } from "styled-components";
import "./global.css";
import "./fonts.css";
import "./reset.css";
import "simplebar/dist/simplebar.min.css";
import "react-toastify/dist/ReactToastify.css";
import "swiper/swiper-bundle.min.css";
import "@3beehivetech/components/dist/index.css";
import "@3beehivetech/components/shared/index.css";
import "react-day-picker/dist/style.css";
import "react-circular-progressbar/dist/styles.css";
import {
  BP, Colors, Font, Rem,
} from "./Theme";

export default createGlobalStyle`
    * {
      box-sizing: border-box;
    }
    
    html {
        font-size: 100%;
        font-family: ${Font.sans};
        font-weight: bold;
        background-color: ${Colors.white};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        scroll-behavior: smooth;
    }

    body {
      position: relative;
      margin: 0;
      padding: 0;
      max-width: 100%;
      color: ${Colors.black};
      background-color: ${Colors.white};
      transition: opacity .4s ease-out .4s;
      overflow-x: hidden;
      padding-top: ${Rem(0)};

      &.theme-black {
        color: ${Colors.white};
        background-color: ${Colors.black};
      }
      
      &.theme-yellow {
        //color: ${Colors.white};
        //background-color: ${Colors.yellow};
      }
      
      &:not(.has-user) {
        overflow: hidden;
        max-height: 100%;
      }
      
      &.has-menu {
        overflow: hidden;
      }
      
      &.has-modal {
        overflow: hidden;
      }

      &.has-relative-header {
        padding-top: ${Rem(60)};
      }
      
      &.has-fixed-nav {
        padding-top: ${Rem(174)};
        @media(${BP.ipad}) {
          padding-top: ${Rem(184)};
        }
      }
    }
    
    .imgHero{
      @media(${BP.tablet}) {
        max-width: 500px;
      }
      @media (${BP.ipad}){
        max-width: 600px;
      }
      @media (${BP.desktop}){
        max-width: 800px;
      }
      @media (${BP.large}){
        max-width: 900px;
      }
    }

    .arrow{
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px; 
    }
    .up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    .gcd-txt-container {
      display: flex !important;
      justify-content: center !important;
    }

    .gcd-txt-control {
      position: absolute;
      min-width: 180px;
      border-radius: 10px;
      border: 1px solid #ccc;
      max-width: 300px;
    }

    .down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
    .react-dropdown{
      min-width: 350px;
    }
    .black-background {
      background-color:${Colors.black};
    }
    .white-background {
      background-color:${Colors.white};
    }
    .color-yellow-background {
      color: ${Colors.blackYellow};
      background-color:${Colors.yellow40};
      white-space: nowrap;
      border-radius: 30px;
      padding: 1px 20px;
      @media (${BP.ipad}) {
        padding: 1px 20px;
      }
    }
    .color-green-background {
      color: ${Colors.blackGreen};
      background-color:${Colors.greenlight};
      white-space: nowrap;
      border-radius: 30px;
      padding: 1px 20px;
      @media (${BP.ipad}) {
        padding: 1px 20px;
      }
    }
    .color-green-primary-background {
      color: ${Colors.white};
      background-color:${Colors.greenPrimary};
    }
    .color-pink-background {
      color: ${Colors.blackPink};
      white-space: nowrap;
      background-color:${Colors.pink};
      border-radius: 30px;
      padding: 1px 20px;
      @media (${BP.ipad}) {
        padding: 1px 20px;
      }
    }
    .color-yellow {
      color: ${Colors.yellow};
    }
    .color-green {
      color: ${Colors.greenPrimary};
    }
    .color-pink {
      color: ${Colors.pinkText};
    }
    .color-white {
      color: ${Colors.white};
    }
    .color-black {
      color: ${Colors.black};
    }
    .text-underline {
      text-decoration: underline;
    }
    strong {
      font-weight: bold;
    }
    
    .grecaptcha-badge {
      z-index: 1;
    }

    /* emulate 70% width column with padding */
    .col-70 {
      @media (${BP.desktop}) {
        padding-right: 2.5% !important;
        padding-left: 2.5% !important;
      }
      @media (${BP.large}) {
        padding-right: 5% !important;
        padding-left: 5% !important;
      }
      @media (${BP.big}) {
        padding-right: 10% !important;
        padding-left: 10% !important;
      }
    }
    
    /* emulate 70% width column with padding for all children */
    .col-70-children {
      & > * {
        @media (${BP.desktop}) {
          padding-right: 2.5% !important;
          padding-left: 2.5% !important;
        }
        @media (${BP.large}) {
          padding-right: 5% !important;
          padding-left: 5% !important;
        }
        @media (${BP.big}) {
          padding-right: 10% !important;
          padding-left: 10% !important;
        }
      }
    }
`;
