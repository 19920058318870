import { useContext } from "react";
import { DataContext } from "../contexts/DataContext";

const useNewsletterListId = () => {
  const { languages } = useContext(DataContext);
  
  const getListId = (type) => {
    const currentLanguage = languages?.filter((language) => language.isCurrent);
    const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "it";

    if (type === "education") {
      switch (currentLanguageCode) {
      case "es":
        return 108; 
      case "fr":
        return 128; 
      case "de":
        return 129; 
      case "en":
        return 130; 
      default:
        return 2; 
      }
    }

    if (type === "international"){
      switch (currentLanguageCode) { 
      case "fr":
        return 142; 
      case "de":
        return 141; 
      case "en":
        return 140; 
      default:
        return 140;   
      }
    }
    
    if (type === "businessPage") {
      return 136;
    }

    if (type === "store") {
      switch (currentLanguageCode) {
      case "es":
        return 108; 
      case "fr":
        return 128; 
      case "de":
        return 129; 
      case "en":
        return 130; 
      default:
        return 103; 
      }
    }

    switch (currentLanguageCode) {
    case "es":
      return 108; 
    case "fr":
      return 128; 
    case "de":
      return 129; 
    case "en":
      return 130; 
    default:
      return 6; 
    }
  };

  return { 
    getListId,
  };
};

export default useNewsletterListId;
