import React, { useEffect, useState, createContext } from "react";

export const DataContext = createContext({});

export const DataContextProvider = (props) => {
  const [meta, setMeta] = useState();
  const [selectedSectionId, setSelectedSectionId] = useState(props.data == null ? null : props.data.menu.tecnologia[0].id);
  const [showTopMenu, setShowTopMenu] = useState(false);
  const [currentLanguageSuffix, setCurrentLanguageSuffix] = useState(props.data?.menu?.languages.find((l) => l.isCurrent === true)?.baseUrl ?? "");

  useEffect(() => {
    if (!props.data) return;
    let currentLanguage = props.data.menu.languages.find((l) => l.isCurrent === true);
    if (currentLanguage) {
      setCurrentLanguageSuffix(currentLanguage.baseUrl);
    }
  }, [props.data]);

  return (
    <DataContext.Provider
      value={{
        theme: props.data?.theme ? props.data.theme : "white",
        menu: props.data?.menu,
        labels: props.data?.labels,
        pageLabels: props.data?.pageLabels,
        pageImages: props.data?.images,
        products: props.data?.products,
        plans: props.data?.plans,
        seo: {
          ...props.data?.seo,
          meta: {
            ...props.data?.seo.meta,
            ...meta,
          },
        },
        setMeta,
        selectedSectionId,
        setSelectedSectionId,
        showTopMenu,
        setShowTopMenu,
        path: props.path,
        currentLanguageSuffix: currentLanguageSuffix === "/" ? "" : currentLanguageSuffix,
        languages: props.data == null ? null : props.data.menu.languages,
        pageLanguagesCodes: props.data?.pageLanguagesCodes,
        localizedPaths: props.data?.localizedPaths,
        i18nLink: (link) => {
          if (
            link.indexOf("http") === 0
            || link.indexOf("//") === 0
            || link.indexOf("#") === 0
          ) {
            return link;
          }
          const paths = props.data?.globalLocalizedPaths;
          const fixedLink = link.length > 1 && link[link.length-1] === "/" ? link.substr(0, link.length-1) : link;
          if (paths && paths[fixedLink] && paths[fixedLink][props.lang]) {
            return paths[fixedLink][props.lang];
          }
          return null;
        },
        i18nOasiLink: (link) => {
          const langSuffix = currentLanguageSuffix === "/" ? "" : currentLanguageSuffix;
          return `${process.env.PUBLIC_SITE_URL}${langSuffix}${link}`;
        },
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
