import styled from "styled-components";
import { BP, Rem, Font } from "./Theme";

export const StyledTitleHero = styled.h1`
  text-transform: uppercase !important;
  font-size: ${Rem(38)} !important;
  line-height: ${Rem(40)} !important;
  font-style: normal !important;
  font-family: ${Font.sans} !important;
  @media (${BP.ipad}) {
    font-size: ${Rem(55)} !important;
    line-height: ${Rem(58)} !important;
    
  }
`;

export const StyledSubTitleHero = styled.h2`
  font-size: ${Rem(20)} !important;
  font-weight: 100 !important;
  font-style: normal !important;
  line-height: normal !important;
  font-family: ${Font.sans} !important;
  padding-top: 10px;
  @media (${BP.ipad}) {
    font-size: ${Rem(25)} !important;
  }
`;

export const StyledTitle = styled.h2`
  text-transform: uppercase;
  font-size: ${Rem(14)};
  line-height: ${Rem(16)};
  letter-spacing: ${Rem(2)};

  @media (${BP.ipad}) {
    font-size: ${Rem(15)};
  }
`;

export const StyledBigText = styled.h3`
  font-size: ${Rem(30)};
  line-height: ${Rem(37)};
  padding-bottom: ${Rem(30)};

  @media (${BP.ipad}) {
    font-size: ${Rem(50)};
    line-height: ${Rem(55)};
  }
`;

export const StyledParagraph = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(30)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(30)};
  }
`;
