import React from "react";
import { StyledIcon } from "./style";
import loadable from "@loadable/component";

const Component = loadable(props => import(`./SVG/${props.iconName}`), {
  cacheKey: props => props.iconName,
});

export { default as icons } from "./icons";

export default ({
  color = "black",
  icon,
  onClick,
  height,
}) => {

  return (
    <StyledIcon onClick={onClick}>
      <Component iconName={icon} color={color} height={height} />
    </StyledIcon>
  );
};
