import React, { useEffect, useState } from "react";
import useModal from "../hooks/useModal";

export const LayoutContext = React.createContext({});

export const LayoutContextProvider = (props) => {
  const { Modal, setModalContent } = useModal();
  const [data, setData] = useState({
    scrollDirection: "up",
    setModalContent,
  });

  useEffect(() => {
    let lastScrollTop = 0;
    window.addEventListener(
      "scroll",
      () => {
        let st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
          setData({
            ...data,
            scrollDirection: "down", 
          });
        } else {
          setData({
            ...data,
            scrollDirection: "up", 
          });
        }
        lastScrollTop = st <= 0 ? 0 : st;
      },
      false,
    );
  }, []);
  return (
    <LayoutContext.Provider value={data}>
      {props.children}
      {Modal}
    </LayoutContext.Provider>
  );
};
