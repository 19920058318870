import { useCallback, useContext } from "react";
import { DataContext } from "../contexts/DataContext";

const useTranslation = () => {
  const { labels } = useContext(DataContext);
  const t = useCallback(
    (key, params) => {
      let value = key.split(".").reduce((a, b) => a === undefined ? undefined : a[b], labels);
      if (value === undefined) {
        return key;
      }
      if (params != null) {
        value = Object.keys(params).reduce((a, b) => { 
          if (a.indexOf(`{{${b}}}`) >= 0) {
            return a.replace(`{{${b}}}`, params[b]);
          }
          if (a.indexOf(`{{${b}, number}}`) >= 0) {
            return a.replace(`{{${b}, number}}`, new Intl.NumberFormat().format(params[b]));
          }
          return a;
        }, value);
      }
      return value;
    }, 
    [labels],
  );

  return { 
    t, 
  };
};

export default useTranslation;
