import React, { useState, useEffect, useContext } from 'react'
import { useMenuContext } from '@3beehivetech/components'
import Link from '../Link/Link'
import {
  StyledHamburgerItem,
  StyledHamburgerSubItem,
  StyledSeparetor,
} from './style.jsx'

export default ({ item, index, urlParams, itemState, setItemState }) => {
  const { closeMenu } = useMenuContext()
  const [visibilitySubItem, setVisibilitySubItem] = useState(0)

  useEffect(() => {
    if (itemState != index) {
      setVisibilitySubItem(0)
    }
  }, [itemState])

  return (
    <div>
      <div
        key={index}
        style="display: flex; padding: 5px 0px;"
        onClick={() => {
          setItemState(index)
          setVisibilitySubItem((prevState) => !prevState)
        }}
      >
        <StyledHamburgerItem>
          {item.label ? item.label.toUpperCase() : null}
        </StyledHamburgerItem>
        <div>
          <i class={visibilitySubItem ? 'arrow up' : 'arrow down'}></i>
        </div>
      </div>

      <div
        style={{
          display: visibilitySubItem ? 'block' : 'none',
          marginLeft: '10px',
        }}
      >
        {item.subMenu?.map((subMenu, index) => {
          return (
            <>
              {subMenu.href && (
                <Link
                  key={index}
                  to={subMenu.href ? subMenu.href : ''}
                  urlParams={urlParams}
                >
                  <StyledHamburgerSubItem onClick={() => closeMenu()}>
                    {subMenu.label ? subMenu.label : null}
                  </StyledHamburgerSubItem>
                </Link>
              )}
            </>
          )
        })}
      </div>
      <StyledSeparetor />
    </div>
  )
}
