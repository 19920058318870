import React, { useContext } from "react";
import { toast } from "react-toastify";
import { DataContext } from "../../contexts/DataContext";
import useTranslation from "../../hooks/useTranslation";
import { Footer as CustomFooter } from "@3beehivetech/components";
import { StaticImage } from "gatsby-plugin-image";
import useNewsletterListId from "../../hooks/useNewsletterListId";

const Footer = () => {
  const { t } = useTranslation();
  const { getListId } = useNewsletterListId();
  const { languages, currentLanguageSuffix, i18nOasiLink, i18nLink } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";
  toast.configure();

  const footerElements = [
    {
      sectionTitle: t("footer.adopters.title"),
      items: [
        {
          text: t("footer.adopters.elements.1"),
          href: i18nLink("/beekeepers"),
        },
        {
          text: t("footer.adopters.elements.2"),
          href: i18nOasiLink("/alveari/"),
        },
        {
          text: t("footer.adopters.elements.3"),
          href: i18nLink("/regala-un-alveare"),
        },
        {
          text: t("footer.adopters.elements.4"),
          href: i18nLink("/app-adopters"),
        },
      ],
    },
    {
      sectionTitle: t("footer.beekeepers.title"),
      items: [
        {
          text: t("footer.beekeepers.elements.1"),
          href: i18nLink("/tecnologia"),
        },
        {
          text: t("footer.beekeepers.elements.2"),
          href: "/oscar-3bee",
        },
      ],
    },
    {
      sectionTitle: t("footer.business.title"),
      items: [
        {
          text: t("footer.business.elements.1"),
          href: i18nLink("/business"),
        },
        {
          text: t("footer.business.elements.2"),
          href: i18nLink("/business"),
        },
        {
          text: t("footer.business.elements.3"),
          href: i18nLink("/box"),
        },
        {
          text: t("footer.business.elements.4"),
          href: "/impactcall",
        },
      ],
    },
    {
      sectionTitle: t("footer.about.title"),
      items: [
        {
          text: t("footer.about.elements.1"),
          href: i18nLink("/chi-siamo"),
        },
        {
          text: t("footer.about.elements.2"),
          href: "https://join.com/companies/3bee",
        },
        {
          text: t("footer.about.elements.3"),
          href: `https://blog.3bee.com${currentLanguageSuffix}/adopters`,
        },
        {
          text: t("footer.about.elements.4"),
          href: "/documents/red.pdf",
        },
      ],
    },
    {
      sectionTitle: t("footer.payment.title"),
      items: [
        {
          content: (
            <div className="flex items-center gap-2">
              <StaticImage
                src="../../../static/2020/images/footer/paypal.png"
                width={107}
                height={35}
                alt="paypal"
              />
              <StaticImage
                src="../../../static/2020/images/footer/sepa.png"
                width={95}
                height={35}
                alt="sepa"
              />
            </div>
          ),
        },
        {
          text: t("footer.payment.elements.1"),
          href: i18nOasiLink("/returns-refunds"),
        },
        {
          text: t("footer.payment.elements.2"),
          href: "/documents/cookie_policy.pdf",
        },
        {
          text: t("footer.payment.elements.3"),
          href: "/documents/termini_condizioni.pdf",
        },
      ],
    },
  ];

  const renderfooterElements = footerElements.map(fE => (
    <CustomFooter.Section key={fE.sectionTitle} title={fE.sectionTitle}>
      {fE.items.map(item =>
        item.content ? (
          <CustomFooter.Item key={item.text}>{item.content}</CustomFooter.Item>
        ) : (
          <CustomFooter.Item key={item.text} text={item.text} href={item.href} />
        ),
      )}
    </CustomFooter.Section>
  ));

  return (
    <CustomFooter
      lang={currentLanguageCode}
      threeBeeDescription={t("footer.3beedesc")}
      newsletterLabel={t("footer.newsletterLabel")}
      newsletterListId={getListId()}
      onNewsletterSuccess={() =>
        toast.success(t("footer.newsletterSuccess"), {
          position: "bottom-center",
        })
      }
      onNewsletterError={() =>
        toast.error(t("negativeMessages"), {
          position: "bottom-center",
        })
      }
    >
      {renderfooterElements}
    </CustomFooter>
  );
};

export default Footer;
