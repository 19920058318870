import React, { useContext, useState } from 'react'
import { useMenuContext } from '@3beehivetech/components'
import HamburgerItem from './HamburgerItem'
import LanguageSelectorFlagStyle from '../LanguageSelectorFlagStyle/LanguageSelectorFlagStyle'
import { DataContext } from '../../contexts/DataContext'
import Link from '../Link/Link'

export default ({ items, urlParams }) => {
  const { closeMenu } = useMenuContext()
  const [ itemState, setItemState] = useState([]);
  const { labels } = useContext(DataContext)

  return (
    <>
      {items.map((item, index) => {
        return <HamburgerItem item={item} index={index} urlParams={urlParams} itemState = {itemState} setItemState={setItemState} />
      })}
      <div style="display: flex; justify-content: space-between; margin-top: 10px;">
        <LanguageSelectorFlagStyle onClick={() => closeMenu()} />
        <div style="display: block;">
          <Link to="chat">
            <div style="text-align: right; padding: 4px 0px; cursor: pointer;">
              {labels?.HELP}
            </div>
          </Link>
          <Link to="https://app.3bee.com">
            <div style="text-align: right; padding: 4px 0px;">Login</div>
          </Link>
        </div>
      </div>
    </>
  )
}
