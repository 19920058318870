import styled, { css } from "styled-components";
import { Rem, BP, Colors } from "./Theme";

const maxWidth = 1248;

export const StyledGrid = styled.div`
  position: relative;
  width: 100%;
`;
/**
 * @props noMargin, isFull, isFullMobile
 */
export const StyledGridRow = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto ${Rem(84)};
  padding: 0 ${Rem(20)} 0 ${Rem(25)};

  @media (${BP.ipad}) {
    margin: 0 auto ${Rem(94)};
    max-width: ${Rem(maxWidth)};
  }

  ${(props) => 
    props.isCourses
    && css`
      margin: 0 auto;
      background: ${Colors.white};
      border-radius: 10px;
      padding: 3rem 1.25rem;
      @media (${BP.ipad}) {
        padding: 4rem 10rem;
        max-width: 50rem;
      }
    `}

  ${(props) => 
    props.noMargin
    && css`
      margin-bottom: 0;

      @media (${BP.ipad}) {
        margin-bottom: 0;
      }
    `}
  
  ${(props) =>
    props.isFull
    && css`
      max-width: 100%;
      padding: 0;

      @media (${BP.ipad}) {
        max-width: 100%;
      }
    `}

  ${(props) =>
    props.isFullMobile
    && css`
      max-width: 100%;
      padding: 0;

      @media (${BP.ipad}) {
        max-width: ${Rem(maxWidth)};
        padding: 0 ${Rem(20)};
      }
    `}
`;
