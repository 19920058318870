import styled from "styled-components";
import { Rem } from "../../commons/Theme";

export const StyledIcon = styled.span`
  position: relative;
  display: inline-block;
  width: ${Rem(16)};
  font-size: 0;
  line-height: 0;
  
  > svg {
    width: 100%;
  }
`;

export const StyledIconV2 = styled.span`
  font-size: 0;
  line-height: 0;
  
  > svg {
    width: auto;
    height:  ${(props) => props.height ? props.height: 1};
  }
`;
