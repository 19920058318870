import styled, { css } from "styled-components";
import {
  BP,
  Rem,
} from "../../commons/Theme";

export const StyledLoader = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(${Rem(10)});
  z-index: 999999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-out 0s;

  ${(props) =>
    props.isVisible
    && css`
      opacity: 1;
      pointer-events: all;
    `}

  > img {
    width: ${Rem(100)};

    @media (${BP.ipad}) {
      width: ${Rem(180)};
    }
  }
`;
