import React from "react";
import { Link as GatsbyLink } from "gatsby";

/**
 * @typedef {{to: string; children: React.ReactChildren}} Props
 * @param props {Props}
 */

const Link = ({ to, children, urlParams, target, onClick }) => {
  const openFreshChat = () => {
    //console.log("openChat")
    if (typeof window !== "undefined") {
      window.FreshworksWidget("open");
    }
  }

  const scrollToRef = (id, yOffset = 0) => {
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({
      top: y,
      behavior: "smooth",
    });
  };

  const isExternalLink = to?.startsWith("http")
  const isScrollTo = to?.startsWith("#")
  const isChat = to?.startsWith("chat")

  if (to) {
    if (isScrollTo) {
      return <div onClick={() => {
        if (onClick) {
          onClick();
        }
        scrollToRef(to.replace(/#/i, ''))
      }}>{children}</div>
    }

    if (isChat) {
      return <div onClick={() => {
        if (onClick) {
          onClick();
        }
        openFreshChat()
      }}>{children}</div>
    }

    if (isExternalLink) {
      return <a href={to + (urlParams ? urlParams : "")} target={target} onClick={onClick}>{children}</a>;
    }

    return <GatsbyLink to={to + (urlParams ? urlParams : "")} onClick={onClick}>{children}</GatsbyLink>;
  }
  return <>{children}</>
};

export default Link;
