import React, { useContext, useEffect } from 'react'
import { useLocation } from '@reach/router'
import Link from '../Link/Link'
import { DataContext } from '../../contexts/DataContext'
import IconV2, { icons } from '../Icon/IconV2'
import { Colors } from '../../commons/Theme'
import { Menu, ThreeBeeLogo } from '@3beehivetech/components'
import SelectionList from '../SelectionList/SelectionList'
import HamburgerList from '../HamburgerList/HamburgerList'
import { StyledMenuContainer } from './style'
import FormRedeemCodeV2_static from '../FormRedeemCodeV2/FormRedeemCodeV2_static'
import { useState } from 'react'
import clsx from 'clsx'

const HeaderFloat_3BC = () => {
  const location = useLocation()
  const urlParams = location.search
  const { labels, i18nLink, i18nOasiLink, path } = useContext(DataContext)

  const [logoLoaded, setLogoLoaded] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'gtm.load',
        originalLocation:
          document.location.protocol +
          '//' +
          document.location.hostname +
          document.location.pathname +
          document.location.search,
      })
    }
  }, [])

  // #region BUSINESS

  const biodiversityProjects = [
    {
      url: i18nLink('/business/'),
      title: labels?.BUSINESS_ADOPT,
      description: labels?.BUSINESS_ADOPT_DESC,
      icon: icons.hive2,
    },
    {
      url: i18nLink('/business/'),
      title: labels?.BOSCO_NETTARIFERO,
      description: labels?.BOSCO_NETTARIFERO_DESC,
      icon: icons.tree,
      isNew: true,
    },
    /*{
      url: i18nLink("/terreno-biodiversita/"),
      title: labels?.SOIL_REGENERATION,
      description: labels?.SOIL_REGENERATION_DESC,
      icon: icons.soil,
    },*/
    //{
    //  url: i18nLink("/business/"),
    //  title: labels?.BUSINESS_HIVE,
    //  description: labels?.BUSINESS_HIVE_DESC,
    //  icon: icons.house,
    //},
  ]

  const companyEngagement = [
    {
      title: labels?.corporateGift,
      url: i18nLink('/business/regali-aziendali/'),
      icon: icons.gift2,
      description: labels?.CORPORATE_GIFT_DESC,
    },
    {
      title: labels?.BUSINESS_EDUCATION,
      url: i18nLink('/business/academy/'),
      icon: icons.education,
      description: labels?.BUSINESS_EDUCATION_DESC,
      isNew: true,
    },
    // {
    //   title: labels?.TEAM_BUILDING,
    //   url: i18nLink("/business/academy/"),
    //   icon: icons.team,
    //   description: labels?.TEAM_BUILDING_DESC,
    // },
    //{
    //  url: i18nOasiLink("/owner/preview/business/"),
    //  title: labels?.OASI,
    //  description: labels?.OASIDESC,
    //  icon: icons.multipleOasi,
    //},
  ]

  // #endregion

  // #region COLLABORA
  const agriTech = [
    {
      url: i18nLink('/tecnologia/'),
      title: labels?.WORK_WITH_US_BEEKEPER,
      description: labels?.WORK_WITH_US_BEEKEPER_DESC,
      icon: icons.beeCircles,
    },
    {
      url: i18nLink('/terreno-biodiversita/'),
      title: labels?.WORK_WITH_US_TERRAIN_OWNER,
      description: labels?.WORK_WITH_US_TERRAIN_OWNER_DESC,
      icon: icons.soil,
    },
    {
      url: i18nLink('/business/beenergy/'),
      title: labels?.BEENERGY,
      description: labels?.BEENERGY_DESC,
      icon: icons.beenergy,
    },
  ]
  const stores = [
    {
      url: i18nLink('/box/'),
      title: labels?.WORK_WITH_US_STORE,
      description: labels?.WORK_WITH_US_STORE_DESC,
      icon: icons.shopstore,
    },
  ]

  const didactics = [
    {
      url: i18nLink('/education/'),
      title: labels?.WORK_WITH_US_SCHOOL,
      description: labels?.WORK_WITH_US_SCHOOL_DESC,
      icon: icons.school,
    },
  ]

  const workwithus = [
    {
      title: labels?.IMPACT_BEENER,
      url: i18nLink('/call-4-beener/'),
      description: labels?.IMPACT_BEENER_DESC,
      icon: icons.bee3Bee,
    },
  ]

  // #endregion

  // #region ADOTTA
  const itemsAdotta = [
    {
      url: i18nLink('/adotta-alveare/'),
      title: labels?.ADOPT_HIVE,
      description: labels?.ADOPT_HIVE_DESC,
      icon: icons.hive2,
    },
    {
      url: i18nLink('/adotta-un-albero/'),
      title: labels?.ADOPT_TREE,
      description: labels?.ADOPT_TREE_DESC,
      icon: icons.tree,
    },
  ]

  // #endregion

  // #region IMPATTO

  const scientificResearch = [
    {
      title: labels?.IMPACT_TECH,
      url: i18nLink('/originals/'),
      description: labels?.IMPACT_TECH_DESC,
      icon: icons.tech2,
    },
  ]

  const projectAndCaseStudios = [
    {
      title: labels?.IMPACT_TGM,
      url: i18nLink('/thegreatmeltdown/'),
      description: labels?.IMPACT_TGM_DESC,
      icon: icons.iceCream,
    },
    /* {
      url: i18nLink("/impactcall/"),
      title: labels?.IMPACTCALL,
      description: labels?.IMPACTCALLDESC,
      icon: icons.stars,
    },*/
  ]

  const menuItemsHamburger_v2 = [
    {
      label: labels?.ADOPT,
      subMenu: [
        {
          label: labels?.DETAILS,
          href: i18nLink('/adotta-alveare/'),
        },
        {
          label: labels?.adottaApe,
          href: i18nLink('/adotta-un-ape/'),
        },
        {
          label: labels?.ADOPT_TREE,
          href: i18nLink('/adotta-un-albero/'),
        },
        {
          label: 'Box 3Bee',
          href: i18nLink('/box/adottatori/'),
        },
        {
          label: labels?.APP_ADOPTERS,
          href: i18nLink('/app-adopters/'),
        },
      ],
    },
    {
      label: labels?.GIFT,
      subMenu: [
        {
          label: labels?.GIVE_A_BEEHIVE,
          href: i18nLink('/regala-un-alveare/'),
        },
        {
          label: labels?.corporateGift,
          href: i18nLink('/business/regali-aziendali/'),
        },
      ],
    },
    {
      label: labels?.CSR_SHORT,
      subMenu: [
        {
          label: labels?.CSR,
          href: i18nLink('/business/'),
        },
        {
          label: labels?.HIVEB2B,
          href: i18nLink('/business/'),
        },
        {
          label: 'Academy 3Bee',
          href: i18nLink('/business/academy/'),
        },
        {
          label: labels?.SHOPBEEBOX,
          href: i18nLink('/box/'),
        },
      ],
    },
    {
      label: labels?.workwithus,
      subMenu: [
        {
          label: labels?.IMPACT_BEENER,
          href: i18nLink('/call-4-beener/'),
        },
        {
          label: labels?.BEEKEEPER,
          href: i18nLink('/tecnologia/'),
        },
        {
          label: labels?.imastore,
          href: i18nLink('/box/'),
        },
        {
          label: labels?.terrainOwner,
          href: i18nLink('/terreno-biodiversita/'),
        },
        {
          label: labels?.imaschool,
          href: i18nLink('/education/'),
        },
        {
          label: labels?.imacompany,
          href: i18nLink('/business/'),
        },
      ],
    },
    {
      label: labels?.IMPACT,
      subMenu: [
        {
          label: labels?.technology,
          href: i18nLink('/originals/'),
        },
        {
          label: 'The Great Meltdown',
          href: i18nLink('/thegreatmeltdown/'),
        },
        {
          label: labels?.EDUCATION,
          href: i18nLink('/education/'),
        },
        {
          label: labels?.BEEKEEPER_COURSES,
          href: i18nLink('/corso/'),
        },
      ],
    },
    {
      label: labels?.WHOUS,
      subMenu: [
        {
          label: 'Team 3Bee',
          href: i18nLink('/chi-siamo/'),
        },
      ],
    },
  ]

  // #endregion

  const centerButton = {
    label: labels?.ADOPT,
    icon: icons?.hive,
    content: <SelectionList items={itemsAdotta} urlParams={urlParams} />,
  }

  const leftButton = [
    {
      label: labels?.workwithus,
      content: (
        <>
          <SelectionList
            group={labels?.MENU_AGRITECH}
            items={agriTech}
            urlParams={urlParams}
          />
          <SelectionList
            group={labels?.MENU_STORES}
            items={stores}
            urlParams={urlParams}
          />
          <SelectionList
            group={labels?.WORKWITHUS}
            items={workwithus}
            urlParams={urlParams}
          />
        </>
      ),
      icon: icons.bee3,
    },
    {
      label: labels?.CSR_SHORT,
      content: (
        <>
          <SelectionList
            group={labels?.MENU_DIVERSITY_PROJECTS}
            items={biodiversityProjects}
            urlParams={urlParams}
          />
          <SelectionList
            group={labels?.MENU_BUSINESS_ENGAGEMENT}
            items={companyEngagement}
            urlParams={urlParams}
          />
        </>
      ),
      icon: icons?.bagbee,
    },
  ]

  const rightButton = [
    path !== 'adotta-alveare'
      ? {
          label: labels?.IMPACT,
          content: (
            <>
              <SelectionList
                group={labels?.MENU_SCIENTIFIC_RESEARCH}
                items={scientificResearch}
                urlParams={urlParams}
              />
              <SelectionList
                group={labels?.MENU_PROJECT_CASE_STUDIOS}
                items={projectAndCaseStudios}
                urlParams={urlParams}
              />
              <SelectionList
                group={labels?.MENU_EDUCATION}
                items={didactics}
                urlParams={urlParams}
              />
            </>
          ),
          icon: icons.shuttle3Bee,
        }
      : {
          label: labels.REDEEM,
          content: <FormRedeemCodeV2_static />,
          icon: icons.card,
        },
    {
      label: labels?.MENU,
      icon: icons.hamburger,
      content: (
        <HamburgerList items={menuItemsHamburger_v2} urlParams={urlParams} />
      ),
    },
  ]

  return (
    <StyledMenuContainer>
      <Menu
        homeComponent={
          <a
            href={i18nLink('/')}
            className={clsx(
              'box-border block w-24 h-10 md:h-14 md:w-[155px] overflow-hidden object-contain transition-all duration-300 ease-out',
              {
                '!w-10 md:!w-14 hover:!w-24 md:hover:!w-[155px]': logoLoaded,
              }
            )}
          >
            <div className="w-24 h-full md:w-36">
              <ThreeBeeLogo
                ref={() => {
                  setTimeout(() => setLogoLoaded(true), 2000)
                }}
                height="100%"
                width="100%"
              />
              <span className="sr-only">Home</span>
            </div>
          </a>
        }
      >
        {leftButton.map((item, index) => (
          <Link
            to={item.href ? item.href : ''}
            urlParams={urlParams}
            key={index}
          >
            <Menu.Button
              text={item.label}
              icon={
                <IconV2
                  icon={item.icon}
                  height="30px"
                  color={Colors.grayDark}
                />
              }
              onClick={item.onClick ? () => item.onClick() : null}
            >
              {item.content && <Menu.Content>{item.content}</Menu.Content>}
            </Menu.Button>
          </Link>
        ))}

        <Link
          to={centerButton.href ? centerButton.href : ''}
          urlParams={urlParams}
        >
          <Menu.CenterButton
            text={centerButton.label}
            icon={
              <IconV2
                icon={centerButton.icon}
                height="55px"
                color={Colors.grayDark}
              />
            }
          >
            {centerButton.content && (
              <Menu.Content>{centerButton.content}</Menu.Content>
            )}
          </Menu.CenterButton>
        </Link>

        {rightButton.map((item, index) => (
          <Link
            to={item.href ? item.href : ''}
            urlParams={urlParams}
            key={index}
          >
            <Menu.Button
              text={item.label}
              icon={
                <IconV2
                  icon={item.icon}
                  height="30px"
                  color={Colors.grayDark}
                />
              }
              onClick={item.onClick ? () => item.onClick() : null}
            >
              {item.content && <Menu.Content>{item.content}</Menu.Content>}
            </Menu.Button>
          </Link>
        ))}
      </Menu>
    </StyledMenuContainer>
  )
}

export default HeaderFloat_3BC
