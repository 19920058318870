import React from "react";
import { StyledLoader } from "./style";

export default ({ isVisible }) => {
  return (
    <StyledLoader isVisible={isVisible}>
      <img src="/2020/images/loading.gif" />
    </StyledLoader>
  );
};
