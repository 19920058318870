import styled, { css } from "styled-components";
import {
  BP, Colors, Font, Rem, Shadow, ShadowWhite,
} from "./Theme";
import { StyledIcon } from "../components/Icon/style";
import { StyledTitle } from "./Typography";

export const StyledForm = styled.form``;

export const StyledFormFieldset = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledFormBlock = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${Rem(8)};
  &:first-child&:last-child {
    width: 100%;
  }
  ${(props) => 
    props.isCourses
    && css`
      display: flex;
      flex-direction: column;
      gap: 1rem;
      input {
        border-radius: 6px;
        padding: 1rem;
        &::placeholder {
          color: transparent;
        }
      }
      textarea {
        border-radius: 6px;
        &::placeholder {
          color: transparent;
        }
      }
    `}
`;

export const StyledFormInput = styled.input`
  appearance: none;
  display: block;
  width: ${(props) => props.width ?? "100%"};
  border-radius: 0;
  font-family: ${Font.sans};
  border: 1px solid ${Colors.transparent};
  border-color: ${Colors.black50};
  padding: ${Rem(10)} ${Rem(16)};
  font-size: ${Rem(14)};
  resize: none;
  height: ${(props) => props.height};
  border-radius: 5px; 

  ${(props) =>
    props.isBlack
    && css`
      border-bottom-color: ${Colors.white50};
      color: ${Colors.black};
      -color: ${Colors.transparent};

      & + ${StyledFormLabel} {
        color: ${Colors.white};
      }
    `}

  ${(props) =>
    props.isLigthYellow
      && css`
        background-color: ${Colors.yellowClear};
        border-color: ${Colors.yellow80};
        color: ${Colors.black};
        -color: ${Colors.transparent};
  
        & + ${StyledFormLabel} {
          color: ${Colors.white};
        }
      `}

  ${(props) =>
    props.isYellow
    && css`
      border-bottom-color: ${Colors.white};
      color: ${Colors.white};
      background-color: ${Colors.transparent};

      & + ${StyledFormLabel} {
        color: ${Colors.white};
        opacity: 0.8;
      }
    `}


  &:focus {
    ${Shadow};

    ${(props) =>
    props.isLigthYellow
      && css`
          ${ShadowWhite};
        `}

    ${(props) =>
    props.isYellow
    && css`
        ${ShadowWhite};
      `}
  }
`;

export const StyledFormLabel = styled.label`
  position: absolute;
  top: ${(props) => props.top ?? Rem(10)};
  left: ${(props) => props.left ?? 0};
  color: ${Colors.black};
  opacity: 0.4;
  pointer-events: none;
  transition: all 0.15s ease-out 0s;
  padding-left: ${Rem(10)};
  ${(props) =>
    props.isHidden
    && css`
      opacity: 0 !important;
    `}

  ${StyledFormInput}:focus + & {
    transform: translateX(${Rem(16)});
  }
`;

export const StyledTitleFormCreate = styled.div`
  box-shadow: 1px 3px 3px 3px rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  ${StyledTitle} {
    padding-top: ${Rem(14)};
    padding-bottom: ${Rem(10)};
    margin: ${Rem(10)};
  }

  ${(props) =>
    props.standAlone
    && css`
      margin-left: 0%;
      width: 90%;
      @media (${BP.ipad}) {
        margin-left: 5%;
        width: 80%;
      }
    `}
`;

export const StyledFormCouponDoneText = styled.h2`
  margin: ${Rem(16)};
  padding: ${Rem(16)};
  font-size: ${Rem(24)};
  line-height: ${Rem(26)};
`;

export const StyledFormSubmit = styled.button`
  position: relative;
  appearance: none;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  min-width: ${Rem(180)};
  max-width: ${Rem(287)};
  height: ${Rem(60)};
  padding: 0 ${Rem(20)};
  font-size: ${Rem(18)};
  font-family: ${Font.sans};
  letter-spacing: 0.5px;
  white-space: nowrap;
  color: ${Colors.white};
  background-color: ${Colors.yellow};
  text-decoration: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  z-index: 2;
  transition: filter 0.25s ease-in-out 0s;

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    height: ${Rem(70)};
    padding: 0 ${Rem(20)};
  }

  ${StyledIcon} {
    width: ${Rem(10)};
    margin-top: ${Rem(3)};
    margin-left: ${Rem(28)};
  }

  &[disabled] {
    pointer-events: none;
    filter: grayscale(1);
  }

  ${(props) => 
    props.isCourses
    && css`
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 5px 10px rgba(255, 176, 0, 0.25);
      @media (${BP.ipad}) {
        height: unset;
        padding: 1.5rem 12.5625rem;
      }
    `}
`;

export const StyledFormSelect = styled.div`
  position: relative;

  &:after {
    display: block;
    position: absolute;
    content: '';
    width: ${Rem(15)};
    height: ${Rem(9)};
    top: 50%;
    right: ${Rem(16)};
    background-image: url('/2020/svg/icons/chevron-down.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    pointer-events: none;
  }

  > select {
    border-radius: 5px;
    font-family: ${Font.sans};
    letter-spacing: ${Rem(2)};
    appearance: none;
    display: block;
    width: 100%;
    height: ${Rem(50)};
    border: 1px solid ${Colors.gray};
    padding: ${Rem(10)} ${Rem(32)} ${Rem(10)} ${Rem(16)};

    ${(props) =>
    props.isLigthYellow
        && css`
          background-color: ${Colors.yellowClear};
          border-color: ${Colors.yellow80};
          color: ${Colors.black};
          -color: ${Colors.transparent};
    
          & + ${StyledFormLabel} {
            color: ${Colors.white};
          }
        `}
      
  }
`;

export const StyledFormToggle = styled.label`
  position: relative;
  display: inline-block;
  width: ${Rem(54)};
  height: ${Rem(26)};
  padding-left: ${Rem(4)};
  margin-top: ${Rem(4)};
  transform: scale(0.8);
  @media (${BP.ipad}) {
    transform: scale(1);
  }

  > input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span {
      background-color: ${Colors.yellow};
    }

    &:focus + span {
    }

    &:checked + span:before {
      transform: translateX(${Rem(28)});
      box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.4);
    }
  }

  > span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: ${Rem(24)};

    &:before {
      position: absolute;
      content: '';
      height: ${Rem(24)};
      width: ${Rem(24)};
      left: ${Rem(1)};
      bottom: ${Rem(1)};
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
      box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.4);
    }
  }
`;
export const StyledFormToggleFlag = styled.label`
  position: relative;
  display: inline-block;
  width: ${Rem(54)};
  height: ${Rem(26)};
  padding-left: ${Rem(4)};
  margin-top: ${Rem(4)};
  transform: scale(0.8);
  @media (${BP.ipad}) {
    transform: scale(1);
  }

  > input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span {
      background-color: #ccc;
    }

    &:focus + span {
    }

    &:checked + span:before {
      background-image: url('/2020/images/biodiversity/spain-flag.png');
      background-size: cover;
      background-position: center;
      transform: translateX(${Rem(28)});
      box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.4);
    }
  }

  > span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: ${Rem(24)};

    &:before {
      position: absolute;
      content: '';
      height: ${Rem(24)};
      width: ${Rem(24)};
      left: ${Rem(1)};
      bottom: ${Rem(1)};
      background-color: white;
      background-image: url('/2020/images/biodiversity/ita-flag.jpg');
      background-size: cover;
      background-position: center;
      transition: 0.4s;
      border-radius: 50%;
      box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.4);
    }
  }
`;

export const StyledFormCheckboxInput = styled.input.attrs({ 
  type: "checkbox",
})`
  /* Hide the browser's default checkbox */
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
`;

export const StyledFormCheckboxCheckmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: ${Rem(18)};
  height: ${Rem(18)};
  border-radius: ${Rem(3)};
  border: 1px solid ${Colors.yellow};

  @media (${BP.ipad}) {
    width: ${Rem(28)};
    height: ${Rem(28)};
  }

  /* Create the checkmark/indicator */
  &:after {
    /* Hidden when not checked */
    content: "";
    position: absolute;
    display: none;

    /* Style the checkmark/indicator */
    left: ${Rem(4)};
    top: ${Rem(0)};
    width: ${Rem(5)};
    height: ${Rem(10)};
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    @media (${BP.ipad}) {
      left: ${Rem(6)};
      top: ${Rem(2)};
      width: ${Rem(10)};
      height: ${Rem(15)};
    }
  }
`;

export const StyledFormCheckbox = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  height: ${Rem(18)};
  line-height: ${Rem(18)};
  padding-left: ${Rem(18 + 10)};
  margin: ${Rem(8)} 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: normal;

  @media (${BP.ipad}) {
    height: ${Rem(28)};
    line-height: ${Rem(28)};
    padding-left: ${Rem(28 + 10)};
    white-space: nowrap;
  }

  /* On mouse-over, add a grey background color */
  &:hover ${StyledFormCheckboxInput} ~ ${StyledFormCheckboxCheckmark} {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a yellow background */
  & ${StyledFormCheckboxInput}:checked ~ ${StyledFormCheckboxCheckmark} {
    background-color: ${Colors.yellow};
  }

  /* Show the checkmark when checked */
  & ${StyledFormCheckboxInput}:checked ~ ${StyledFormCheckboxCheckmark}:after {
    display: block;
  }
`;
